import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hero from "../components/layout/Hero";
import HomeServices from "../components/layout/HomeServices";
import TransformSection from "../components/layout/TransformSection";
import ConsultationSection from "../components/layout/ConsultationSection";
import CustomerSection from "../components/layout/CustomerSection";
import CaseStudy from "../components/layout/CaseStudy";
import PageTitle from "../components/layout/PageTitle";


const Home = () => {
  return(
    <div className="App">
      <PageTitle />
      <Hero />
      <HomeServices />
      <TransformSection />
      <CustomerSection />
      <ConsultationSection />
      <CaseStudy />
    </div>
  );
}
  

export default Home;
