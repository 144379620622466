import React from "react";
import { Container } from 'react-bootstrap';

import WOW from "wowjs";

class Hero extends React.Component {

	componentDidMount() {
    	new WOW.WOW().init();
  	}
  	render() {
		return (
			<div className="HereoBanner automotive-back">
			    <div className="Banner_Content">
					<Container fluid>
						<h2 className="pagehead-title wow fadeInUp">USHA CASE STUDIES</h2>
						<div className="red-border"> </div>
						<p>Digipanda provides software development and mobile app development services that are driven by the ambition to help you derive maximum value from your IT investments.</p>
					</Container>
				</div>
			</div>
		);
	}
};

export default Hero;
