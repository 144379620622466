import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hero from "../components/ADAcompliance/Hero";
import DevelopServices from "../components/ADAcompliance/DevelopServices";
import Process from "../components/ADAcompliance/Process";
import Customers from "../components/ADAcompliance/Customers";
import Achivements from "../components/ADAcompliance/Achivements";
import OtServices from "../components/ADAcompliance/OtServices";
import PageTitle from "../components/ADAcompliance/PageTitle";


import '../assets/Webdevelopment.css';



const ADAcompliance = () => {
	return(
		<div className="App">
			<PageTitle />
	    	<Hero />
	    	<DevelopServices />
	    	<OtServices />
	    	<Process />
	    	<Customers/>
	    	<Achivements />
	  </div>
	)
}

export default ADAcompliance;
