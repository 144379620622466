import React from "react";
import { Row, Col, Container, } from 'react-bootstrap';
import magentodev from '../../images/magentodev.png';
import manufacturing from '../../images/manufacturing.png';
import flexible from '../../images/flexible.png';
import postequirements from '../../images/postequirements.png';
import vendor from '../../images/vendor.png';
import q6 from '../../images/q6.png';

import {Link} from "react-router-dom";

const DevelopServices = () => {

	return (
	     <div className="HomeServices padding">
			<Container fluid>
				<Row>
					<Col lg="5">
						<h2 className="wow fadeInUp">digiPanda IS ONE OF THE TOP <span>ECOMMERCE</span> SOLUTIONS COMPANY WITH OVER 50,000 MAN YEARS OF EXPERIENCE.</h2>
						<p className="wow fadeInUp">digiPanda is a ISO 9001 certified web development company, backed by a strong workforce of 300+ experts providing high-performance, custom web development services of any complexity with incredible competence. digiPanda is a leading offshore web development company that have been delivering result-oriented web solutions to SME's across the globe for more than 15+ years. We are committed towards adhering to latest technology trends and successfully implementing end-to-end web solutions that enhances the brand value of your your business. The development cycle at digiPanda enables us to take the scalability and reliability of our web solutions to next level so, you can focus on your core business processes. The robust web applications developed by our web developers enhances your online prominence through effective and interactive customer-engagement. Hire web developers from digiPanda to get professionally tailored web applications and solutions.</p>
						<Link to="/contact-us" className="same-btn wow fadeInUp">Get Free Estimation</Link>
					</Col>
					<Col lg="7">
						<Row>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={magentodev} className="icon" alt="icon" width="64" /></span>
									<h3 className="wow fadeInUp">ECOMMERCE SHOPPING CART</h3>
									<p className="wow fadeInUp">digiPanda offers end-to-end website design, web application development and portal development services for your business.</p>
									
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={manufacturing} className="icon" alt="icon" width="64" /></span>
									<h3 className="wow fadeInUp">MODULE DEVELOPMENT</h3>
									<p className="wow fadeInUp">digiPanda build scalable and secure ebusiness and ecommerce solutions for customers across various business verticals.</p>
									
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={flexible} className="icon" alt="icon" width="64" /></span>
									<h3 className="wow fadeInUp">CUSTOM ECOMMERCE DEVELOPMENT</h3>
									<p className="wow fadeInUp">digiPanda offers PHP development services that enhances the business demands of our customers across all verticals.</p>
									
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={postequirements} className="icon" alt="icon" width="64" /></span>
									<h3 className="wow fadeInUp">ECOMMERCE WEBSITE DEVELOPMENT</h3>
									<p className="wow fadeInUp">digiPanda provides cost effective and high performance ASP.NET development services for growing businesses and startups.</p>
									
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={vendor} className="icon" alt="icon" width="64" /></span>
									<h3 className="wow fadeInUp">MULTI STORE DEVELOPMENT</h3>
									<p className="wow fadeInUp">We leverage time and cost saving advantages of open source technologies, frameworks and platforms to deliver scalable web applications.</p>
									
								</div>
							</Col>
							<Col md="6">
								<div className="Service_Col">
									<span className="wow fadeInUp"><img src={q6} className="icon" alt="icon" width="64" /></span>
									<h3 className="wow fadeInUp">MAINTENANCE & MIGRATION</h3>
									<p className="wow fadeInUp">We have 50+ web testers to provide high performance and 100% reliable website testing services.</p>
									
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default DevelopServices;
