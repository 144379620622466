import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import sia from '../../images/sia.png';

import {Link} from "react-router-dom";

import { FaTelegramPlane } from "react-icons/fa";

import WOW from "wowjs";

class Hero2 extends React.Component {

	componentDidMount() {
    	new WOW.WOW().init();
  	}
  	
  	render() {
		return (
			<div className="HereoBanner Webdevelopment digipanda-portfolio2 pb-0">
			    <div className="Banner_Content section">
					<Container fluid>
						<Row className="align-items-center">
							<Col lg="5" className="left-text-sec">
								<h2 className="text-uppercase wow fadeInUp text-white"> SIA </h2>
								<p className="text-white wow fadeInUp">SIA is an Australian overseas education consultation service. It’s a student support service that equips you with overseas education.  It offers services like career guidance. Offers work experience in the desired discipline and helps you earn as you learn. It also helps with other necessary facilities that will be needed like accommodation and student care. </p>
								<p className="text-white wow fadeInUp pt-0">It offers a holistic approach to its consultancy services. They offer services like assistance in the entire process of applying and getting selected, smart search options that ensure you apply for colleges that serve all your needs, student care, visa accessibility etc. </p>
								<Link target="_blank" to={{ pathname: "https://siaconsultant.com" }} className="red-btn wow fadeInUp"> Download On the Appstore  &nbsp; <FaTelegramPlane /> </Link>
							</Col>
							<Col lg="7" className="banner-right-img cms-img">
								<div className="right-img-sec">
									<img src={sia} alt="Developemnt" />
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		);
	}
};

export default Hero2;
