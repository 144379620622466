import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hero from "../components/Webdesigndevelopment/Hero";
import DevelopServices from "../components/Webdesigndevelopment/DevelopServices";
import Technology from "../components/Webdesigndevelopment/Technology";
import Process from "../components/Webdesigndevelopment/Process";
import IndustryBussinessjs from "../components/Webdesigndevelopment/IndustryBussinessjs";
import Commitment from "../components/Webdesigndevelopment/Commitment";
import Customers from "../components/Webdesigndevelopment/Customers";
import Success from "../components/Webdesigndevelopment/Success";
import Achivements from "../components/Webdesigndevelopment/Achivements";
import PageTitle from "../components/Webdesigndevelopment/PageTitle";

import '../assets/Webdevelopment.css';



const Webdesigndevelopment = () => {
	return(
		<div className="App">
			<PageTitle />
	    	<Hero />
	    	<DevelopServices />
	    	<Technology />
	    	<Process />
	    	<IndustryBussinessjs />
	    	<Commitment />
	    	<Customers/>
	    	<Success />
	    	<Achivements />
	  </div>
	)
}

export default Webdesigndevelopment;
