import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hero from "../components/Webdevelopment/Hero";
import DevelopServices from "../components/Webdevelopment/DevelopServices";
import Technology from "../components/Webdevelopment/Technology";
import Process from "../components/Webdevelopment/Process";
import IndustryBussinessjs from "../components/Webdevelopment/IndustryBussinessjs";
import Commitment from "../components/Webdevelopment/Commitment";
import Customers from "../components/Webdevelopment/Customers";
import Success from "../components/Webdevelopment/Success";
import Achivements from "../components/Webdevelopment/Achivements";
import PageTitle from "../components/Webdevelopment/PageTitle";


import '../assets/Webdevelopment.css';



const Webdevelopment = () => {
	return(
		<div className="App">
			<PageTitle />
	    	<Hero />
	    	<DevelopServices />
	    	<Technology />
	    	<Process />
	    	<IndustryBussinessjs />
	    	<Commitment />
	    	<Customers/>
	    	<Success />
	    	<Achivements />
	  </div>
	)
}

export default Webdevelopment;
