import React from "react";
import { Row, Col, Container, } from 'react-bootstrap';
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";

import {Link} from "react-router-dom";

const Copyright = () => {

	return (
	    <div className="Copyright">
			<Container fluid>
				<Row className="align-items-center">
					<Col md="6">
						<p className="wow fadeInUp">&copy; 2022 Digipanda. All rights reserved.</p>
					</Col>
					<Col md="6">
						<div className="social-icons">
		                  	<Link to="#" className="fb-icon" target="_blank"><FaFacebookF /></Link>
		                  	<Link to="#" className="tw-icon" target="_blank"><FaTwitter /></Link>
		                  	<Link to="#" className="lnkd-icon" target="_blank"><FaLinkedinIn /></Link>
		                </div>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default Copyright;
