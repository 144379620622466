import React from "react";
import { Row, Col, Container } from 'react-bootstrap';



const ConsultingServices = () => {

	return (
	    <div className="consul_hire benifits">
			<Container fluid>
				<Row>
					<Col lg="10 offset-1" className="text-center">
						<h2 className="wow fadeInUp">In general, the U.S. Department of Justice(DOJ) is responsible for enforcing <span>ADA</span> laws (DOJ).</h2>
						<p className="small-para wow fadeInUp">With cutting-edge AI-based (artificial intelligence) technology, digiPanda Consulting Pvt. Ltd. provides ADA-complying solutions that make it easy for website visitors to traverse the page. We offer online consumers the ADA compliance solution they require to prevent website noncompliance lawsuits, take advantage of ADA tax benefits, and win their business for life. </p>
					</Col>
				</Row>
				<Row>
					<Col lg="6" className="consul_col">
						<div className="consultant-content">
							<h3 className="consul_title wow fadeInUp"> To Create A Website That Complies With ADA </h3>
							<p className="exp wow fadeInUp"> ●	All music, video, and image files should have alt tags.</p>
							<p className="exp wow fadeInUp"> ●	Transcribe audio and video material into text.</p>
							<p className="exp wow fadeInUp"> ●	Recognize the languages of the website in the header code.</p>
							<p className="exp wow fadeInUp"> ●	Provide options and recommendations when consumers experience input problems.</p>
							<p className="exp wow fadeInUp"> ●	Make a logical, unified layout.</p>
						</div>
					</Col>
					<Col lg="6" className="consul_col">
						<div className="consultant-content">
							<h3 className="consul_title wow fadeInUp">Repercussions For ADA violations? </h3>
							<p className="exp wow fadeInUp"> A first ADA violation may result in fines of up to $75,000, and subsequent offenses may result in fines of up to $150,000. State and local governments have the authority to impose additional fines and impose stricter accessibility requirements on companies than the ADA does. </p>
						</div>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default ConsultingServices;
