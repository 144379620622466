import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hero from "../components/Technologies/Hero";
import DevelopServices from "../components/Technologies/DevelopServices";
import Process from "../components/Technologies/Process";
import OtServices from "../components/Technologies/OtServices";
import Customers from "../components/Technologies/Customers";
import Achivements from "../components/Technologies/Achivements";
import Commitment from "../components/Technologies/Commitment";
import IndustryBussinessjs from "../components/Technologies/IndustryBussinessjs";
import PageTitle from "../components/Technologies/PageTitle";



import '../assets/Webdevelopment.css';



const Technologies = () => {
	return(
		<div className="App">
			<PageTitle />
	    	<Hero />
	    	<DevelopServices />
	    	<OtServices />
	    	<Process />
	    	<IndustryBussinessjs />
	    	<Commitment />
	    	<Customers/>
	    	<Achivements />
	  </div>
	)
}

export default Technologies;
