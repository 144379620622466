import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import usha from '../../images/usha.png';

import {Link} from "react-router-dom";

import { FaTelegramPlane } from "react-icons/fa";

import WOW from "wowjs";

class Hero4 extends React.Component {

	componentDidMount() {
    	new WOW.WOW().init();
  	}
  	
  	render() {
		return (
			<div className="HereoBanner Webdevelopment digipanda-portfolio4 pb-0">
			    <div className="Banner_Content section">
					<Container fluid>
						<Row className="align-items-center">
							<Col lg="5" className="left-text-sec">
								<h2 className="text-uppercase wow fadeInUp text-white"> USHA </h2>
								<p className="text-white wow fadeInUp">The Usha Silai app is dedicated to empowering people by teaching sewing skills even in remote areas. It aims to eliminate dependence on individual teachers, commuting to places to learn a skill and paying for fees. It’s a space that reflects the passion for teaching sewing skills and ways to handle machines, promoting self-sufficiency. The endeavours are for all from beginners to advance who are passionate to learn, develop and teach.</p>
								<Link target="_blank" to={{ pathname: "https://www.ushasew.com" }} className="red-btn wow fadeInUp">  Demo Available on Request &nbsp; <FaTelegramPlane /> </Link>
							</Col>
							<Col lg="7" className="banner-right-img cms-img">
								<div className="right-img-sec">
									<img src={usha} alt="Developemnt" />
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		);
	}
};

export default Hero4;
