import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import rekoop from '../../images/rekoop.png';

import {Link} from "react-router-dom";

import { FaTelegramPlane } from "react-icons/fa";

import WOW from "wowjs";

class Hero extends React.Component {

	componentDidMount() {
    	new WOW.WOW().init();
  	}
  	
  	render() {
		return (
			<div className="HereoBanner Webdevelopment digipanda-portfolio">
			    <div className="Banner_Content">
					<Container fluid>
						<Row className="align-items-center">
							<Col lg="6" className="banner-left-img cms-img">
								<div className="left-img">
									<img src={rekoop} alt="Developemnt" />
								</div>
							</Col>
							<Col lg="6" className="banner-right-content">
								<h2 className="wow fadeInUp text-white">REKOOP</h2>
								<p className="text-white">A sustainable solution of bedding. Rekoop presents a range of bedding products derived from PET bottles. At Rekoop PET bottles are used to derive the most eco-friendly polyester fibre globally. The bedding made at Rekoop is sustainable and free from any toxins and hazardous chemicals. The brand ensures to make the world cleaner and greener. It’s an initiative that is decreasing the overall carbon footprint. It is also reducing landfill overload and assisting in water conservation. It’s a unique idea that brings to life an innovative concept. </p>
								<p className="text-white">Practices like molecular tagging of recycled fibre ensure authenticity and traceability to the origin. Rekoop is the future of sustainable beddings. It’s a revolution towards sustainability. </p>
								<Link target="_blank" to={{ pathname: "https://rekoop.pet" }} className="red-btn"> Launch Live Website &nbsp; <FaTelegramPlane /> </Link>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		);
	}
};

export default Hero;
