import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hero from "../components/HireDevelopers/Hero";
import DevelopServices from "../components/HireDevelopers/DevelopServices";
import Process from "../components/HireDevelopers/Process";
import OtServices from "../components/HireDevelopers/OtServices";
import Customers from "../components/HireDevelopers/Customers";
import Achivements from "../components/HireDevelopers/Achivements";
import Commitment from "../components/HireDevelopers/Commitment";
import IndustryBussinessjs from "../components/HireDevelopers/IndustryBussinessjs";
import PageTitle from "../components/HireDevelopers/PageTitle";


import '../assets/Webdevelopment.css';



const HireDevelopers = () => {
	return(
		<div className="App">
			<PageTitle />
	    	<Hero />
	    	<DevelopServices />
	    	<OtServices />
	    	<Process />
	    	<IndustryBussinessjs />
	    	<Commitment />
	    	<Customers/>
	    	<Achivements />
	  </div>
	)
}

export default HireDevelopers;
