import React from "react";
import { Row, Col, Container, } from 'react-bootstrap';
import usafleg from '../../images/usafleg.jpg';
import indiafleg from '../../images/indiafleg.jpg';

import {Link} from "react-router-dom";


const Footer = () => {

	return (
	    <div className="Footer">
			<Container fluid>
				<Row>
					<Col className="menu-listing col contact-col">
						<h4 className="wow fadeInUp">Offices</h4>
						<p className="wow fadeInUp">
							D-111, Floor No. 1, Sector 63, Noida, India
						</p>
						<p className="wow fadeInUp">
							44-70 21st Street, #3061, Long Island City, NY 11101, USA
						</p>
						<p className="wow fadeInUp">
							Sniep 69, 1112 AJ Diemen, Netherlands
						</p>
					</Col>
					<Col className="menu-listing col service-col">
						<Link to="/services"><h4 className="wow fadeInUp">Services</h4></Link>
						<ul>
							<Link to="/web-development">Web Development</Link>
		                    <Link to="/mobile-app-development">Mobile App</Link>
		                    <Link to="/software-development">Development</Link>
						</ul>
					</Col>
					<Col className="menu-listing col solution-col">
						<h4 className="wow fadeInUp">Sotutions</h4>
						<ul>
							<Link to="/outsourcing">IT Outsourcing</Link>
                      		<Link to="/technologies">Technologies</Link>
                      		<Link to="/hire-developers">Hire Developers</Link>
						</ul>
					</Col>
					<Col className="menu-listing col company-col">
						<h4 className="wow fadeInUp">Corporate</h4>
						<ul>
							<Link to="/portfolio">Portfolio</Link>
		                    <Link to="/case-studies">Case Studies</Link>
		                    <Link to="/testimonials">Testimonials</Link>
		                    <Link to="/blog">Blog</Link>
		                    <Link to="/careers">Careers</Link>
		                    <Link to="/contact-us">Contact Us</Link>
						</ul>
					</Col>
					<Col className="menu-listing col hvQues">
						<span className="footer-head hide-xs">Connect with an Expert</span>
						<div className="phone-contact">Talk to us:

						<a href="tel:+1 5163680268"><img width="25" src={usafleg} alt="flag" /> &nbsp; <span className="number">+1-516-368-0268</span></a>

						<a href="tel:+91 8448835766"><img width="25" src={indiafleg} alt="flag" /> &nbsp; <span className="number">+91-844-883-5766</span></a>
						

						</div>
						<div className="rowfull">
							Skype: <a href="skype:digipanda.sales">digipanda.sales</a>
							
							<p>Email: <a href="mailto:sales@digipanda.in">sales@digipanda.in</a></p>
						</div>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default Footer;
