import React, {useState}  from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const Getintouch = () => {

	const [fullName, setFullName] = useState('');
	const [companyName, setCompanyName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [country, setCountry] = useState('');
	const [budget, setBudget] = useState('');
	const [message, setMessage] = useState('');


	async function getResponse() {
		let data = { fullName, companyName, email, phone, country, budget, message }
		if(fullName && email && phone.length === 10)
		{
			const response = await fetch('https://shyamheaven.com/digiPanda/digiDBC/api/contectForm',
				{method: 'POST',body: JSON.stringify(data)});
	  		const result = await response.json();

	  		alert(result.message)

		}
		else{

			alert('please fill the required field')
		}
	

	}


	function submitform(){
		getResponse()
	}


	return(
		<div className="contact-section">
	    	<Container fluid>
				<Row>
					<Col md="12">
						<div className="contact-form">
							<Row>
								<Col lg="8">
									<div className="top-contact">
										<h2 className="h2 sm wow fadeInUp"> Get in touch with us </h2>
										<p className="small-heading"> Please fill the contact form below and one of our representative shall get in touch with you within next 24 hours. </p>
									</div>
									<div className="contactform">
										<Form>
											<Row>
												<Col md="6">
													<Form.Group controlId="exampleForm.ControlInput1">
													    <Form.Control type="text" placeholder="Name*" 
													    	value={fullName} 
													    	onChange={(e) => setFullName(e.target.value)} 
													    />
													</Form.Group>
												</Col>
												<Col md="6">
													<Form.Group controlId="exampleForm.ControlInput1">
													    <Form.Control type="text" placeholder="Company Name" 
													    	value={companyName} 
													    	onChange={(e) => setCompanyName(e.target.value)} 
													    />
													</Form.Group>
												</Col>
											</Row>
											<Row>
												<Col md="6">
													<Form.Group controlId="exampleForm.ControlInput1">
													    <Form.Control type="email" placeholder="Email*" 
													    	value={email} 
													    	onChange={(e) => setEmail(e.target.value)} 
													    />
													</Form.Group>
												</Col>
												<Col md="6">
													<Form.Group controlId="exampleForm.ControlInput1">
													    <Form.Control type="text" placeholder="Phone Number*" 
													    	value={phone} 
													    	onChange={(e) => setPhone(e.target.value)} 
													    />
													</Form.Group>
												</Col>
											</Row>
											<Row>
												<Col md="6">
													<Form.Group controlId="exampleForm.SelectCustomSizeSm">
													    <Form.Control as="select" custom value={country} onChange={(e) => setCountry(e.target.value)}>
														    <option>Select Country</option>
														    <option value="USA">United States of America (USA)</option>
																<option value="UK">United Kingdom (UK)</option>
																<option value="UAE">United Arab Emirates (UAE)</option>
																<option value="Australia">Australia</option>
																<option value="Canada">Canada</option>
																<option value="India">India</option>
																<optgroup label="-----------------------------------------------------------"></optgroup>
																<option value="Afghanistan">Afghanistan</option>
																<option value="Albania">Albania</option>
																<option value="Algeria">Algeria</option>
																<option value="AmericanSamoa">American Samoa</option>
																<option value="Andorra">Andorra</option>
																<option value="Angola">Angola</option>
																<option value="Anguilla">Anguilla</option>
																<option value="Antarctica">Antarctica</option>
																<option value="AntiguaandBarbuda">Antigua and Barbuda</option>
																<option value="Argentina">Argentina</option>
																<option value="Armenia">Armenia</option>
																<option value="Aruba">Aruba</option>
																<option value="Australia">Australia</option>
																<option value="Austria">Austria</option>
																<option value="Azerbaijan">Azerbaijan</option>
																<option value="Bahama">Bahama</option>
																<option value="Bahrain">Bahrain</option>
																<option value="Bangladesh">Bangladesh</option>
																<option value="Barbados">Barbados</option>
																<option value="Belarus">Belarus</option>
																<option value="Belgium">Belgium</option>
																<option value="Belize">Belize</option>
																<option value="Benin">Benin</option>
																<option value="Bermuda">Bermuda</option>
																<option value="Bhutan">Bhutan</option>
																<option value="Bolivia">Bolivia</option>
																<option value="BosniaandHerzegovina">Bosnia and Herzegovina</option>
																<option value="Botswana">Botswana</option>
																<option value="BouvetIsland">Bouvet Island</option>
																<option value="Brazil">Brazil</option>
																<option value="BritishVirginIslands">British Virgin Islands</option>
																<option value="BruneiDarussalam">Brunei Darussalam</option>
																<option value="Bulgaria">Bulgaria</option>
																<option value="BurkinaFaso">Burkina Faso</option>
																<option value="Burundi">Burundi</option>
																<option value="Cambodia">Cambodia</option>
																<option value="Cameroon">Cameroon</option>
																<option value="Canada">Canada</option>
																<option value="CapeVerde">Cape Verde</option>
																<option value="CaymanIslands">Cayman Islands</option>
																<option value="Chad">Chad</option>
																<option value="Chile">Chile</option>
																<option value="China">China</option>
																<option value="ChristmasIsland">Christmas Island</option>
																<option value="Cocos(Keeling) Islands">Cocos(Keeling) Islands</option>
																<option value="Colombia">Colombia</option>
																<option value="Comoros">Comoros</option>
																<option value="Congo">Congo</option>
																<option value="CookIslands">Cook Islands</option>
																<option value="Costa Rica">Costa Rica</option>
																<option value="Croatia">Croatia</option>
																<option value="Cuba">Cuba</option>
																<option value="Cyprus">Cyprus</option>
																<option value="CzechRepublic">Czech Republic</option>
																<option value="Denmark">Denmark</option>
																<option value="Djibouti">Djibouti</option>
																<option value="Dominica">Dominica</option>
																<option value="DominicanRepublic">Dominican Republic</option>
																<option value="East Timor">East Timor</option>
																<option value="Ecuador">Ecuador</option>
																<option value="Egypt">Egypt</option>
																<option value="El Salvador">El Salvador</option>
																<option value="EquatorialGuinea">Equatorial Guinea</option>
																<option value="Eritrea">Eritrea</option>
																<option value="Estonia">Estonia</option>
																<option value="Ethiopia">Ethiopia</option>
																<option value="Faroe Islands">Faroe Islands</option>
																<option value="Fiji">Fiji</option>
																<option value="Finland">Finland</option>
																<option value="France">France</option>
																<option value="French Guiana">French Guiana</option>
																<option value="French Polynesia">French Polynesia</option>
																<option value="Gabon">Gabon</option>
																<option value="Gambia">Gambia</option>
																<option value="Georgia">Georgia</option>
																<option value="Germany">Germany</option>
																<option value="Ghana">Ghana</option>
																<option value="Gibraltar">Gibraltar</option>
																<option value="Greece">Greece</option>
																<option value="Greenland">Greenland</option>
																<option value="Grenada">Grenada</option>
																<option value="Guadeloupe">Guadeloupe</option>
																<option value="Guam">Guam</option>
																<option value="Guatemala">Guatemala</option>
																<option value="Guinea">Guinea</option>
																<option value="Guinea-Bissau">Guinea Bissau</option>
																<option value="Guyana">Guyana</option>
																<option value="Haiti">Haiti</option>
																<option value="Honduras">Honduras</option>
																<option value="HongKong">Hong Kong</option>
																<option value="Hungary">Hungary</option>
																<option value="Iceland">Iceland</option>
																<option value="India">India</option>
																<option value="Indonesia">Indonesia</option>
																<option value="Iraq">Iraq</option>
																<option value="Ireland">Ireland</option>
																<option value="Iran">Iran</option>
																<option value="Israel">Israel</option>
																<option value="Italy">Italy</option>
																<option value="Jamaica">Jamaica</option>
																<option value="Japan">Japan</option>
																<option value="Jordan">Jordan</option>
																<option value="Kazakhstan">Kazakhstan</option>
																<option value="Kenya">Kenya</option>
																<option value="Kiribati">Kiribati</option>
																<option value="Korea">Korea</option>
																<option value="Kuwait">Kuwait</option>
																<option value="Kyrgyzstan">Kyrgyzstan</option>
																<option value="Latvia">Latvia</option>
																<option value="Lebanon">Lebanon</option>
																<option value="Lesotho">Lesotho</option>
																<option value="Liberia">Liberia</option>
																<option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
																<option value="Liechtenstein">Liechtenstein</option>
																<option value="Lithuania">Lithuania</option>
																<option value="Luxembourg">Luxembourg</option>
																<option value="Macau">Macau</option>
																<option value="Madagascar">Madagascar</option>
																<option value="Malawi">Malawi</option>
																<option value="Malaysia">Malaysia</option>
																<option value="Maldives">Maldives</option>
																<option value="Mali">Mali</option>
																<option value="Malta">Malta</option>
																<option value="Marshall Islands">Marshall Islands</option>
																<option value="Martinique">Martinique</option>
																<option value="Mauritania">Mauritania</option>
																<option value="Mauritius">Mauritius</option>
																<option value="Mayotte">Mayotte</option>
																<option value="Mexico">Mexico</option>
																<option value="Micronesia">Micronesia</option>
																<option value="Monaco">Monaco</option>
																<option value="Mongolia">Mongolia</option>
																<option value="Monserrat">Monserrat</option>
																<option value="Morocco">Morocco</option>
																<option value="Mozambique">Mozambique</option>
																<option value="Myanmar">Myanmar</option>
																<option value="Nambia">Nambia</option>
																<option value="Nauru">Nauru</option>
																<option value="Nepal">Nepal</option>
																<option value="Netherlands Antilles">Netherlands Antilles</option>
																<option value="Netherlands">Netherlands</option>
																<option value="New Caledonia">New Caledonia</option>
																<option value="New Zealand">New Zealand</option>
																<option value="Nicaragua">Nicaragua</option>
																<option value="Niger">Niger</option>
																<option value="Nigeria">Nigeria</option>
																<option value="Niue">Niue</option>
																<option value="Norfolk Island">Norfolk Island</option>
																<option value="Northern Mariana Islands">Northern Mariana Islands</option>
																<option value="Northwest Territories">Northwest Territories</option>
																<option value="Norway">Norway</option>
																<option value="Oman">Oman</option>
																<option value="Pakistan">Pakistan</option>
																<option value="Palau">Palau</option>
																<option value="Panama">Panama</option>
																<option value="Papua New Guinea">Papua New Guinea</option>
																<option value="Paraguay">Paraguay</option>
																<option value="Peru">Peru</option>
																<option value="Philippines">Philippines</option>
																<option value="Pitcairn">Pitcairn</option>
																<option value="Poland">Poland</option>
																<option value="Portugal">Portugal</option>
																<option value="Puerto Rico">Puerto Rico</option>
																<option value="Qatar">Qatar</option>
																<option value="Romania">Romania</option>
																<option value="Russian Federation">Russian Federation</option>
																<option value="Rwanda">Rwanda</option>
																<option value="Saint Lucia">Saint Lucia</option>
																<option value="Samoa">Samoa</option>
																<option value="San Marino">San Marino</option>
																<option value="Sao Tome and Principe">Sao Tome and Principe</option>
																<option value="Saudi Arabia">Saudi Arabia</option>
																<option value="Senegal">Senegal</option>
																<option value="Seychelles">Seychelles</option>
																<option value="SierraLeone">Sierra Leone</option>
																<option value="Singapore">Singapore</option>
																<option value="Slovakia">Slovakia</option>
																<option value="Slovenia">Slovenia</option>
																<option value="Solomon Islands">Solomon Islands</option>
																<option value="Somalia">Somalia</option>
																<option value="South Africa">South Africa</option>
																<option value="Spain">Spain</option>
																<option value="Sri Lanka">Sri Lanka</option>
																<option value="St. Helena">St. Helena</option>
																<option value="St.Kitts and Nevis">St.Kitts and Nevis</option>
																<option value="St.Pierre and Miquelon">St.Pierre and Miquelon</option>
																<option value="Sudan">Sudan</option>
																<option value="Suriname">Suriname</option>
																<option value="Swaziland">Swaziland</option>
																<option value="Sweden">Sweden</option>
																<option value="Switzerland">Switzerland</option>
																<option value="Syrian Arab Republic">Syrian Arab Republic</option>
																<option value="Taiwan">Taiwan</option>
																<option value="Tajikistan">Tajikistan</option>
																<option value="Tanzania">Tanzania</option>
																<option value="Thailand">Thailand</option>
																<option value="Togo">Togo</option>
																<option value="Tokelau">Tokelau</option>
																<option value="Tonga">Tonga</option>
																<option value="Trinidad and Tobago">Trinidad and Tobago</option>
																<option value="Tunisia">Tunisia</option>
																<option value="Turkey">Turkey</option>
																<option value="Turkmenistan">Turkmenistan</option>
																<option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
																<option value="Tuvalu">Tuvalu</option>
																<option value="UAE">United Arab Emirates (UAE)</option>
																<option value="Uganda">Uganda</option>
																<option value="Ukraine">Ukraine</option>
																<option value="UK">United Kingdom (UK)</option>
																<option value="Uruguay">Uruguay</option>
																<option value="USA">United States of America (USA)</option>
																<option value="Uzbekistan">Uzbekistan</option>
																<option value="Vanuatu">Vanuatu</option>
																<option value="Venezuela">Venezuela</option>
																<option value="VietNam">VietNam</option>
																<option value="Western Sahara">Western Sahara</option>
																<option value="Yemen">Yemen</option>
																<option value="Yugoslavia">Yugoslavia</option>
																<option value="Zaire">Zaire</option>
																<option value="Zambia">Zambia</option>
																<option value="Zimbabwe">Zimbabwe</option>
													    </Form.Control>
													</Form.Group>
												</Col>
												<Col md="6">
													<Form.Group controlId="exampleForm.SelectCustomSizeLg">
													    <Form.Control as="select" custom value={budget} onChange={(e) => setBudget(e.target.value)}>
													      <option>Select Budget</option>
													      <option>Less than $10,000</option>
													      <option>$10,000 - $25,000</option>
													      <option>$25,000 - $50,000</option>
													      <option>$50,000 - $100,000</option>
													      <option>More than $100,000</option>
													    </Form.Control>
													</Form.Group>
												</Col>
											</Row>
											<Row>
												<Col md="12">
													<Form.Group controlId="exampleForm.ControlTextarea1">
													    <Form.Control as="textarea" rows={5} placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)} />
													</Form.Group>
												</Col>
											</Row>
											<Row>
												<Col md="12">
													<Button className="same-btn" variant="primary" type="button" onClick={submitform} >
													  Submit Information
													</Button>
												</Col>
											</Row>
										</Form>
									</div>	
								</Col>
								<Col lg="4">
									<div className="right-form-address">
										<div className="blue-bar">
											<div className="sm-content wow fadeInUp"> Collaborating across timezones to deliver excellence. </div>
										</div>
										<div className="OurAddress">
											<h2 className="h3">USA Office</h2>
											<p> 44-70 21st Street, #3061, Long Island City, NY 11101, USA <br/>
											 Phone +1 516 368 0268
											</p>
										</div>
										<div className="OurAddress">
											<h2 className="h3">INDIA OFFICE</h2>
											<p> D-111, Floor No. 1, Sector 63, <br/> Noida, India <br/> Phone +91 844 883 5766
											</p>
										</div>
										<div className="OurAddress">
											<h2 className="h3">NL OFFICE</h2>
											<p> Sniep 69, 1112 AJ Diemen, Netherlands <br/> Phone +9304 720 464 
											</p>
										</div>
									</div>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
			</Container>
	  	</div>
	)
}

export default Getintouch;
