import React from "react";
import { Row, Col, Container } from 'react-bootstrap';
import awards from '../../images/awards.png';




const Achivements = () => {

	return (
	    <div className="achivements pdtop-bottom clearfix">
			<Container fluid>
				<Row>
					<Col lg="3" md="6">
						<div className="awards wow fadeInUp">
							<img src={awards} alt="logo" />
							<p>TOP 10 SOFTWARE DEVELOPMENT COMPANY</p>
							<span>CIO REVIEW . 2021</span>
						</div>
					</Col>
					<Col lg="3" md="6">
						<div className="awards wow fadeInUp">
							<img src={awards} alt="logo" />
							<p>TOP BLOCKCHAIN APP DEVELOPMENT COMPANY</p>
							<span>CLUTCH . 2021</span>
						</div>
					</Col>
					<Col lg="3" md="6">
						<div className="awards wow fadeInUp">
							<img src={awards} alt="logo" />
							<p>TOP 10 MOBILE APP DEVELOPMENT COMPANY</p>
							<span>GOODFIRMS . 2021</span>
						</div>
					</Col>
					<Col lg="3" md="6">
						<div className="awards wow fadeInUp">
							<img src={awards} alt="logo" />
							<p>TOP 5 REACT NATIVE DEVELOPMENT COMPANY</p>
							<span>MOBILE APP DAILY . 2021</span>
						</div>
					</Col>
				</Row>
			</Container>
	    </div>
	);
};

export default Achivements;
