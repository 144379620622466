import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hero from "../components/DigitalMarketingService/Hero";
import Customers from "../components/DigitalMarketingService/Customers";
import Achivements from "../components/DigitalMarketingService/Achivements";
import OtServices from "../components/DigitalMarketingService/OtServices";
import PageTitle from "../components/DigitalMarketingService/PageTitle";


import '../assets/Webdevelopment.css';



const DigitalMarketingService = () => {
	return(
		<div className="App">
			<PageTitle />
	    	<Hero />
	    	<OtServices />
	    	<Customers/>
	    	<Achivements />
	  </div>
	)
}

export default DigitalMarketingService;
