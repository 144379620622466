import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import MainHeader from "./components/Comon/MainHeader";
import Footer from "./components/layout/Footer";
import Copyright from "./components/layout/Copyright";
import Home from './views/Home';
import Contact from './views/Contact';
import Webdevelopment from './views/Webdevelopment';
import Appdevelopment from './views/Appdevelopment';
import Softwaredevelopment from './views/Softwaredevelopment';
import Consulting from './views/Consulting';
import QaTesting from './views/QaTesting';
import BdAnalytics from './views/BdAnalytics';
import Iothings from './views/Iothings';
import Blockchain from './views/Blockchain';
import ArtificialIntelligence from './views/ArtificialIntelligence';
import CloudComputing from './views/CloudComputing';
import Outsourcing from './views/Outsourcing';
import Technologies from './views/Technologies';
import HireDevelopers from './views/HireDevelopers';
import Automotive from './views/Automotive';
import Entertainment from './views/Entertainment';
import Healthcare from './views/Healthcare';
import Manufacturing from './views/Manufacturing';
import Finance from './views/Finance';
import RealEstate from './views/RealEstate';
import Retail from './views/Retail';
import Logistics from './views/Logistics';
import Travel from './views/Travel';
import Education from './views/Education';
import CaseStudies from './views/CaseStudies';
import Testimonials from './views/Testimonials';
import Careers from './views/Careers';
import TechnicalLead from './views/TechnicalLead';
import Industries from './views/Industries';
import Terms from './views/Terms';
import Privacy from './views/Privacy';
import Services from './views/Services';
import Webdesigndevelopment from './views/Webdesigndevelopment';
import Ecommercesolution from './views/Ecommercesolution';
import PHPdevelopment from './views/PHPdevelopment';
import Dottnetdevelopment from './views/Dottnetdevelopment';
import Androidappdevelopment from './views/Androidappdevelopment';
import IOSappdevelopment from './views/IOSappdevelopment';
import CrossPlatformAppDevelopment from './views/CrossPlatformAppDevelopment';
import ReactNativeAppDevelopment from './views/ReactNativeAppDevelopment';
import JavaDevelopment from './views/JavaDevelopment';
import WrodpressDevelopment from './views/WrodpressDevelopment';
import SOHO from './views/SOHO';
import USHA from './views/USHA';
import Melrose from './views/Melrose';
import SolRepublic from './views/SolRepublic';
import SailaxDBC from './views/SailaxDBC';
import Blog from './views/Blog';
import Portfolio from './views/Portfolio';
import LikeUbereats from './views/LikeUbereats';  
import ADAcompliance from './views/ADAcompliance';  
import DigitalMarketingService from './views/DigitalMarketingService';    
import SEO from './views/SEO';    


import ScrollToTop from './components/ScrollToTop';


import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";


import './assets/App.css';
import './assets/Responsive.css';


function App() {

  return (
    <Router>
      <div className="App">
        <ScrollToTop />
        <MainHeader />
        <Switch>
          <Route exact path="/" component= {Home} />
          <Route path="/contact-us" component= {Contact} />
          <Route path="/web-development" component= {Webdevelopment} />
          <Route path="/mobile-app-development" component= {Appdevelopment} />
          <Route path="/software-development" component= {Softwaredevelopment} />
          <Route path="/it-consulting" component= {Consulting} />
          <Route path="/quality-assurance-testing" component= {QaTesting} />
          <Route path="/big-data-analytics" component= {BdAnalytics} />
          <Route path="/internet-of-things" component= {Iothings} />
          <Route path="/blockchain" component= {Blockchain} />
          <Route path="/artificial-intelligence" component= {ArtificialIntelligence} />
          <Route path="/cloud-computing" component= {CloudComputing} />
          <Route path="/automotive" component= {Automotive} />
          <Route path="/entertainment" component= {Entertainment} />
          <Route path="/healthcare" component= {Healthcare} />
          <Route path="/manufacturing" component= {Manufacturing} />
          <Route path="/finance" component= {Finance} />
          <Route path="/real-estate" component= {RealEstate} />
          <Route path="/retail" component= {Retail} />
          <Route path="/logistics" component= {Logistics} />
          <Route path="/travel" component= {Travel} />
          <Route path="/education" component= {Education} />
          <Route path="/outsourcing" component= {Outsourcing} />
          <Route path="/technologies" component= {Technologies} />
          <Route path="/hire-developers" component= {HireDevelopers} />
          <Route path="/case-studies" component= {CaseStudies} />
          <Route path="/testimonials" component= {Testimonials} />
          <Route path="/careers" component= {Careers} />
          <Route path="/technical-lead" component= {TechnicalLead} />
          <Route path="/industries" component= {Industries} />
          <Route path="/terms-of-use" component= {Terms} />
          <Route path="/privacy-policy" component= {Privacy} />
          <Route path="/services" component= {Services} />
          <Route path="/web-design-development" component= {Webdesigndevelopment} />
          <Route path="/ecommerce-solution" component= {Ecommercesolution} />
          <Route path="/php-development" component= {PHPdevelopment} />
          <Route path="/aspdott-net-development" component= {Dottnetdevelopment} />
          <Route path="/android-app-development" component= {Androidappdevelopment} />
          <Route path="/ios-app-development" component= {IOSappdevelopment} />
          <Route path="/cross-platform-app-development" component= {CrossPlatformAppDevelopment} />
          <Route path="/react-native-app-development" component= {ReactNativeAppDevelopment} />
          <Route path="/java-development" component= {JavaDevelopment} />
          <Route path="/wrodpress-development" component= {WrodpressDevelopment} />
          <Route path="/soho" component= {SOHO} />
          <Route path="/usha" component= {USHA} />
          <Route path="/melrose" component= {Melrose} />
          <Route path="/sol-republic" component= {SolRepublic} />
          <Route path="/sailax-dbc" component= {SailaxDBC} />
          <Route path="/blog" component= {Blog} />
          <Route path="/portfolio" component= {Portfolio} />
          <Route path="/build-a-food-delivery-app-like-ubereats" component= {LikeUbereats} />
          <Route path="/ada-compliance-services" component= {ADAcompliance} />
          <Route path="/digital-marketing-service" component= {DigitalMarketingService} />
          <Route path="/search-engine-optimization" component= {SEO} />
        </Switch>
        <Footer />
        <Copyright/>
      </div>
    </Router>
  );
  
}

export default App;
