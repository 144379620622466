import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hero from "../components/WrodpressDevelopment/Hero";
import DevelopServices from "../components/WrodpressDevelopment/DevelopServices";
import Technology from "../components/WrodpressDevelopment/Technology";
import Process from "../components/WrodpressDevelopment/Process";
import IndustryBussinessjs from "../components/WrodpressDevelopment/IndustryBussinessjs";
import Commitment from "../components/WrodpressDevelopment/Commitment";
import Customers from "../components/WrodpressDevelopment/Customers";
import Success from "../components/WrodpressDevelopment/Success";
import Achivements from "../components/WrodpressDevelopment/Achivements";
import PageTitle from "../components/WrodpressDevelopment/PageTitle";

import '../assets/Webdevelopment.css';




const WrodpressDevelopment = () => {
	return(
		<div className="App">
			<PageTitle />
	    	<Hero />
	    	<DevelopServices />
	    	<Technology />
	    	<Process />
	    	<IndustryBussinessjs />
	    	<Commitment />
	    	<Customers/>
	    	<Success />
	    	<Achivements />
	  </div>
	)
}

export default WrodpressDevelopment;
